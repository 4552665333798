import * as types from './actionTypes';

// toggle the active timer on / off
export const toggleTimer = payload => (dispatch, getState) => {
  dispatch({
    type: types.TOGGLE_ACTIVE,
    payload: payload,
  });
};

// stops the active timer and resets the value to the full durration
export const resetTimer = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESET_ACTIVE,
    payload: payload,
  });
};

// stops the timer and sets the desired full duration for the session
export const updateTimerDuration = payload => (dispatch, getState) => {
  dispatch({
    type: types.CHANGE_FULL_TIME,
    payload: payload,
  });
};

// updates the actual countdown time
export const updateActiveTime = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_ACTIVE_TIME,
    payload: payload,
  });
};

// stores a users custom timer in Firebase
export const createCustomTimer = payload => (dispatch, getState) => {
  // TODO: wire this up
  dispatch({
    type: types.CREATE_CUSTOM_TIMER,
    payload: payload,
  });
};

// toggles first run information
export const updateFirstRun = payload => (dispatch, getState) => {
  const userStorage = window.localStorage;
  userStorage.setItem('om:firstrun', payload);
  dispatch({
    type: types.UPDATE_FIRST_RUN,
    payload: payload,
  });
};
