import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home, TimeSelect } from './Pages';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <div className={styles.pages}>
          <Route exact path="/" component={Home} />
          <Route exact path="/" component={TimeSelect} />
          {/* <Route path="/about" component={About} />
          <Route path="/topics" component={Topics} /> */}
        </div>
      </Router>
    </div>
  );
}

export default App;
