// rootReducer
import { combineReducers } from 'redux';
// import { firebaseStateReducer } from 'react-redux-firebase';
import ui from './uiReducer/reducers';
import timer from './timerReducer/reducers';
export default combineReducers({
  ui,
  timer,
  // firebase: firebaseStateReducer
});

//   routing: routerReducer,
