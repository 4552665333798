import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { updateActiveTime } from '../../redux/modules/timerReducer';
import styles from './Timer.module.scss';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.timeoutTime = props.fullDuration;

    this.state = {
      currentButton: 0,
      seconds: 0,
      minutes: 0,
    };

    this.maxSize = 2;
    this.intervalHandle = null;
  }

  componentDidMount() {
    this.setDisplayTime();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isActive, fullDuration, activeDuration } = this.props;

    if (isActive && isActive !== prevProps.isActive) {
      this.startCountDown();
    }

    if (fullDuration !== prevProps.fullDuration) {
      this.setState({ timer: fullDuration }, () => {
        this.timeoutTime = fullDuration;
        this.setDisplayTime();
      });
    }

    if (activeDuration !== prevProps.activeDuration) {
      this.setDisplayTime();
    }
  }

  tick = () => {
    const { isActive, activeDuration, updateActiveTime } = this.props;
    if (isActive) {
      const sec = activeDuration - 1;

      if (sec >= 0) {
        updateActiveTime(sec);
      }

      if (sec < 0) {
        clearInterval(this.intervalHandle);
      }
    } else {
      clearInterval(this.intervalHandle);
    }
  };

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 1000);
  };

  setDisplayTime = () => {
    const { activeDuration } = this.props;
    const distance = 1000 * activeDuration;
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // console.log('tick :', minutes, seconds, distance);
    this.setState({
      seconds,
      minutes,
    });
  };

  render() {
    const { activeDuration } = this.props;
    const percentage = (activeDuration / this.timeoutTime) * 100;
    const displayMinutes = this.state.minutes.toString().padStart(2, '0');
    const displaySeconds = this.state.seconds.toString().padStart(2, '0');
    return (
      <div className={styles.timerWrapper}>
        <div className={styles.countdown}>
          <CircularProgressbar
            value={percentage}
            text={`${displayMinutes}:${displaySeconds}`}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateActiveTime,
    },
    dispatch,
  );
const enhance = connect(
  // Map redux state to component props
  ({ timer: { fullDuration, activeDuration } }) => ({
    fullDuration,
    activeDuration,
  }),
  mapDispatchToProps,
);

export default enhance(Timer);
