import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateTimerDuration } from '../../redux/modules/timerReducer';
import styles from './TimeSelect.module.scss';
class TimeSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.options = [60, 180, 300];
  }

  handleTimeChange(time) {
    const { updateTimerDuration } = this.props;
    updateTimerDuration(time);
  }

  render() {
    const { isActive, firstRun } = this.props;

    return (
      <div
        className={classNames(styles.timeSelectWrapper, {
          [styles.isActive]: isActive,
        })}
      >
        <div>
          Select the time for how long you would like to meditate.{' '}
          {!firstRun && (
            <span>
              If this your first time, I recommend just starting out with a
              quick one minute rest.
            </span>
          )}
        </div>
        {this.options.map(time => (
          <div
            className={styles.timeOption}
            key={time}
            onClick={() => this.handleTimeChange(time)}
          >
            <div>{time / 60}</div>
          </div>
        ))}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTimerDuration,
    },
    dispatch,
  );

const enhance = connect(
  // Map redux state to component props
  ({ timer: { fullDuration, isActive, firstRun } }) => ({
    fullDuration,
    isActive,
    firstRun,
  }),
  mapDispatchToProps,
);

export default enhance(TimeSelect);
