import React, { Component } from 'react';
import styles from './Login.module.scss';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  render() {
    return <div className={styles.loginWrapper} />;
  }
}
