import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NoSleep from 'nosleep.js';
import { toggleTimer, resetTimer } from '../../redux/modules/timerReducer';
import { Timer, Button, AudioManager } from '../../Components';
import styles from './Home.module.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    this.noSleep = new NoSleep();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isActive } = this.props;

    if (isActive) {
      this.noSleep.enable();
    } else {
      this.noSleep.disable();
    }
  }

  handleRelaxPauseClick() {
    const { toggleTimer, isActive } = this.props;
    toggleTimer(!isActive);
  }

  handleStopClick() {
    const { resetTimer, fullDuration } = this.props;
    resetTimer(fullDuration);
  }

  render() {
    const { isActive } = this.props;

    return (
      <div className={styles.homeWrapper}>
        <div className={styles.timerWrapper}>
          <Timer isActive={isActive} />
        </div>
        <div className={styles.buttons}>
          <Button onClick={() => this.handleRelaxPauseClick()}>
            {!isActive ? <span>Relax</span> : <span>Pause</span>}
          </Button>
          {isActive && (
            <Button onClick={() => this.handleStopClick()}>Stop</Button>
          )}
        </div>
        <AudioManager />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleTimer,
      resetTimer,
    },
    dispatch,
  );

const enhance = connect(
  // Map redux state to component props
  ({ timer: { isActive, fullDuration } }) => ({
    isActive,
    fullDuration,
  }),
  mapDispatchToProps,
);

export default enhance(Home);
