import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateFirstRun } from '../../redux/modules/timerReducer';
import styles from './AudioManager.module.scss';
import chimes from '../../assets/audio/chimes_01.mp3';

class AudioManager extends Component {
  constructor(props) {
    super(props);
    this.audioPlayer = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeDuration, isActive, updateFirstRun } = this.props;
    if (activeDuration !== prevProps.activeDuration) {
      if (activeDuration === 0) {
        updateFirstRun(true);
        this.audioPlayer.current.play();
      }
    }

    if (isActive && isActive !== prevProps.isActive) {
      this.audioPlayer.current.play();
    }
  }

  render() {
    const { type = 'audio/mp3' } = this.props;
    const currentAudioPath = chimes;
    return (
      <div className={styles.audioWrapper}>
        <audio ref={this.audioPlayer} controls={false}>
          <source src={currentAudioPath} type={type} />
        </audio>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFirstRun,
    },
    dispatch,
  );
const enhance = connect(
  // Map redux state to component props
  ({ timer: { activeDuration, isActive } }) => ({
    activeDuration,
    isActive,
  }),
  mapDispatchToProps,
);

export default enhance(AudioManager);
