import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
// import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import history from './history';
import rootReducer from './modules';
// import firebase from './firebase';

const initialState = {};
// // const config = {
// //   userProfile: 'users', // where profiles are stored in database
// //   enableLogging: false,
// //   profileFactory: (userData, profileData) => ({
// //     // how profiles are stored in database
// //     ...profileData,
// //     id: userData.uid,
// //   }),
// //   fileMetadataFactory: uploadRes => {
// //     // upload response from Firebase's storage upload
// //     const {
// //       metadata: { name, fullPath, downloadURLs, timeCreated, size },
// //     } = uploadRes;
// //     // default factory includes name, fullPath, downloadURL
// //     return {
// //       name,
// //       fullPath,
// //       downloadURL: downloadURLs[0],
// //       created: timeCreated,
// //       size,
// //     };
// //   },
// // };
const enhancers = [
  // reactReduxFirebase(fbConfig, { userProfile: 'users', enableLogging: false })
  // reactReduxFirebase(firebase, config),
];

// // , thunk.withExtraArgument(getFirebase)
// const middleware = [routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(thunk),
  ...enhancers,
);

// const store = createStore(
//   connectRouter(history)(rootReducer),
//   initialState,
//   composedEnhancers,
// );

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers,
);
export default store;
